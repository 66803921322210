import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import api from "../../api";
import { createAsyncThunkInstance } from "../createAsyncThunkInstance";
import { isError } from "..";
import {
  ICreateWorkCompletedActRequest,
  ICreateWorkCompletedActResponse,
  IPrintFormWorkCompletedActRequest,
  ISingleWorkCompletedActsResponse,
  IWorkCompletedAct,
  IWorkCompletedActDetail,
  IWorkCompletedActsResponse,
} from "../../api/workCompletedActs/types";
import { createWorkCompletedAct, getPrintForm, updateWorkCompletedAct } from "../../api/workCompletedActs";
import { ErrorType, ICommonInitialState } from "../../shared/types/storeTypes";
import { IFilesAttachResponse } from "../../api/files/types";
import { attachFiles } from "../../api/files";
import { Files, WorkCompletedActsEndpoints } from "../../api/endpoints";
import { IPrintFormResponse } from "../../api/directionLetters/types";

interface IWorkCompletedActsInitialState extends ICommonInitialState {
  workCompletedActs: IWorkCompletedAct[];
  currentWorkCompletedAct: IWorkCompletedAct | null;
  detailsWorkCompletedAct: IWorkCompletedActDetail[];
  
}

const initialState: IWorkCompletedActsInitialState = {
  workCompletedActs: [],
  currentWorkCompletedAct: null,
  detailsWorkCompletedAct: [],
  isLoading: false,
  error: null,
};

export const fetchWorkCompletedActs = createAsyncThunkInstance<
  IWorkCompletedActsResponse,
  undefined
>(
  "workCompletedActs/fetchWorkCompletedActs",
  api.workCompletedActs.getWorkCompletedActs
);

export const fetchSingleWorkCompletedActs = createAsyncThunkInstance<
  ISingleWorkCompletedActsResponse,
  string
>(
  "workCompletedActs/fetchSingleWorkCompletedActs",
  api.workCompletedActs.getSingleWorkCompletedActs
);

export const addWorkCompletedAct = createAsyncThunk<
  ICreateWorkCompletedActResponse,
  ICreateWorkCompletedActRequest,
  { rejectValue: AxiosError }
>(
  "workCompletedActs/addWorkCompletedAct",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await createWorkCompletedAct(params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const modifyWorkCompletedAct = createAsyncThunk<
  ICreateWorkCompletedActResponse,
  { id: string, params: ICreateWorkCompletedActRequest },
  { rejectValue: AxiosError }
>(
  "workCompletedActs/updateWorkCompletedAct",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const { data } = await updateWorkCompletedAct(id,params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const attachFilesToSingleWorkCompletedAct = createAsyncThunk<
  IFilesAttachResponse,
  { id: string; files: File[] },
  { rejectValue: AxiosError }
>(
  "workCompletedActs/attachFilesToSingleWorkCompletedAct",
  async ({ id, files }, { rejectWithValue }) => {
    try {
      const { data } = await attachFiles(WorkCompletedActsEndpoints.SINGLE_WORK_COMPLETED_ACT + `/${id}` + Files.ATTACH_FILES, files);
      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

export const fetchPrintFormWorkAct = createAsyncThunk<
  IPrintFormResponse,
  { id: string; params: IPrintFormWorkCompletedActRequest },
  { rejectValue: AxiosError }
>(
  "workCompletedActs/fetchPrintFormWorkAct",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const { data } = await getPrintForm(id, params);

      return data;
    } catch (error: unknown) {
      return rejectWithValue(error as AxiosError);
    }
  }
);

const workCompletedActsSlice = createSlice({
  name: "workCompletedActs",
  initialState,
  reducers: {
    setWorkCompletedActDetails(state, action) {
      state.detailsWorkCompletedAct = action.payload;
    },
    resetCurrenWorkCompletedAct(state) {
      state.currentWorkCompletedAct = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkCompletedActs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchWorkCompletedActs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.workCompletedActs = action.payload.data;
      })
      .addCase(fetchSingleWorkCompletedActs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleWorkCompletedActs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentWorkCompletedAct = action.payload.data;
      })
      .addMatcher(isError, (state, action: PayloadAction<ErrorType>) => {
        state.isLoading = false;
        state.error = action.payload.response?.data.message;
      });
  },
});

export const workCompletedActsReducer = workCompletedActsSlice.reducer;

export const { setWorkCompletedActDetails,resetCurrenWorkCompletedAct } = workCompletedActsSlice.actions;

