import { RootState, useAppSelector } from "../../store";
import { Link } from "@chakra-ui/react";
import dayjs from "dayjs";
import { InformationBlock } from "../InformationBlock/InformationBlock";
import { InformationItem } from "../InformationBlock/InformationItem";
import { StatusCell } from "../StatusCell/StatusCell";
import { useNavigate } from "react-router-dom";
import { numberWithDate } from "../../helpers/numberWithDate";
import { ChangeModal } from "../../hoc/ChangeModal";
import { useState } from "react";
import { Input } from "../lib/input/Input";
import { useFormik } from "formik";

export function SingleAccessActInformationBlock() {
  const navigate = useNavigate();
  const { currentAccessAct } = useAppSelector(
    (state: RootState) => state.accessActs
  );
  const [changeModal, setChangeModal] = useState<boolean>(false);

  const onSubmit = () => {};
  
  const formik = useFormik({
    initialValues: {
      email: "",
    },

    onSubmit,
  });

  return (
    <>
      <InformationBlock>
        <InformationItem title="Номер">
          {numberWithDate(
            currentAccessAct?.act_number,
            currentAccessAct?.act_creation_date
          )}
        </InformationItem>
        <InformationItem title="Статус">
          <StatusCell value={currentAccessAct?.status ?? ""} />
        </InformationItem>
        <InformationItem title="Организация">
          {currentAccessAct?.a101_org_name}
        </InformationItem>

        <InformationItem title="Договор">
          <Link
            onClick={() => {
              navigate(
                `/my-documents/contracts/${currentAccessAct?.contract_id}`
              );
            }}
            color="#2AB6A5"
          >
            {currentAccessAct?.contract?.name ?? ""}
          </Link>
        </InformationItem>
        <InformationItem title="Срок действия">
          {dayjs(currentAccessAct?.validity_term).format("DD.MM.YYYY")}
        </InformationItem>
        <InformationItem title="Проект">
          {currentAccessAct?.project?.name ?? ""}
        </InformationItem>
        <InformationItem title="Контрагент">
          {currentAccessAct?.counterparty ?? ""}
        </InformationItem>
        <InformationItem title="Руководитель строительства">
          {currentAccessAct?.construction_manager ?? ""}
        </InformationItem>
        <InformationItem title="Руководитель проекта">
          {currentAccessAct?.project_manager ?? ""}
        </InformationItem>
        <InformationItem title="Ответственный за стройконтроль">
          {currentAccessAct?.construction_control_responsible ?? ""}
        </InformationItem>
        <InformationItem
          title="Электронный адрес подрядчика"
          isEdited
          handleEditClick={() => setChangeModal(true)}
        >
          {currentAccessAct?.contractor_email ?? ""}
        </InformationItem>
        <InformationItem title="Дата выхода подряда">
          {dayjs(currentAccessAct?.contractor_exit_date).format("DD.MM.YYYY")}
        </InformationItem>
      </InformationBlock>
      <ChangeModal
        active={changeModal}
        onClose={() => {
          setChangeModal(false);
        }}
        title="Сменить электронный адрес подрядчика"
        children={<Input placeholder="Введите e-mail" name="email" value={formik.values.email} onChange={formik.handleChange}/>}
      />
    </>
  );
}
