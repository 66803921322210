import { useEffect, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import { Flex, Grid, GridItem, useDisclosure } from "@chakra-ui/react";
import { fetchRoles } from "../../store/slices/profile";
import { PrimaryButton } from "../lib/button/Button";
import { Title } from "../lib/title/Title";
import { ProfileBankData } from "./ProfileInfo/ProfileBankData";
import { ProfileName } from "./ProfileName/ProfileName";
import { ProfileTable } from "./ProfileTable/ProfileTable";
import { ProfileInfoCard } from "./ProfileInfo/ProfileInfoCard";
import { ReactComponent as CEO } from "../../images/svg/profile/ceo.svg";
import { ReactComponent as Phone } from "../../images/svg/profile/phone.svg";
import { ReactComponent as EmailLock } from "../../images/svg/profile/email-lock.svg";
import { ReactComponent as EmailStar } from "../../images/svg/profile/email-star.svg";
import { ReactComponent as Position } from "../../images/svg/profile/position.svg";
import { OnboardingLink } from "../OnboardingLink/OnboardingLink";
import { AddEditModal } from "../Modal/ModalRight/AddEditModal";
import { TutorialModal } from "../Modal/ModalCenter/TutorialModal";
import { ChangeNameModal } from "../Modal/ModalCenter/ChangeNameModal";
import { ChangeEmailModal } from "../Modal/ModalCenter/ChangeEmailModal";
import { ChangeModal } from "../../hoc/ChangeModal";
import {checkPermission} from "../../helpers/checkPermission";

export const ProfileCEOContainer = () => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(
    (state: RootState) => state.profile.profileData.profile
  );
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );

  const { screens } = useAppSelector((state: RootState) => state.onboarding);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [changeNameModal, setChangeNameModal] = useState<boolean>(false);
  const [changeEmailModal, setChangeEmailModal] = useState<boolean>(false);
  const [modalActive, setModalActive] = useState<boolean>(false);
  const openModalHandler = () => setModalActive(true);

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  const сhangeNameModalActive = () => {
    setChangeNameModal(true)
  }

  const сhangeEmailModalActive = () => {
    setChangeEmailModal(true)
  }

  return (
    <Flex overflowY="scroll" maxH="100%" direction="column" p={6} gap={10}>
      <Grid
        gap={4}
        gridTemplateRows="248px auto 1fr"
        gridTemplateColumns="repeat(4, 1fr)"
      >
        <GridItem colSpan={2} rowSpan={1}>
          <ProfileName name={profile?.organization.org_name ?? ""} />
        </GridItem>
        <GridItem colSpan={2} rowSpan={2}>
          <ProfileBankData />
        </GridItem>
        <GridItem>
          <ProfileInfoCard
            Icon={CEO}
            title="Гендиректор"
            content={`${profile?.surname} ${profile?.name} ${profile?.patronymic}`}
            isEditable
            сhangeNameModalActive={сhangeNameModalActive}
          />
        </GridItem>
        <GridItem>
          <ProfileInfoCard
            Icon={EmailLock}
            title="E-mail"
            content={profile?.email ?? ""}
            isEditable
            сhangeEmailModalActive={сhangeEmailModalActive}
          />
        </GridItem>
        <GridItem>
          <ProfileInfoCard
            Icon={Phone}
            title="Телефон"
            content={profile?.phone_number ?? "Нет данных"}
          />
        </GridItem>
        <GridItem>
          <ProfileInfoCard
            Icon={EmailStar}
            title="Корпоративный e-mail"
            content={profile?.email ?? ""}
          />
        </GridItem>
        <GridItem>
          <ProfileInfoCard
            Icon={Position}
            title={"Юридический адрес"}
            content={
              profile?.organization.legal_address?.length
                ? profile?.organization.legal_address
                : "Нет данных"
            }
          />
        </GridItem>
        <GridItem>
          <ProfileInfoCard
            Icon={Position}
            title="Фактический адрес"
            content={
              profile?.organization.factual_address?.length
                ? profile?.organization.factual_address
                : "Нет данных"
            }
          />
        </GridItem>
      </Grid>

      {profile?.is_ceo && (
        <Flex direction="column" gap={4}>
          <Flex justify="space-between" align="center">
            <Title>Сотрудники</Title>
            <PrimaryButton onClick={openModalHandler}>
              Добавить сотрудника
            </PrimaryButton>
          </Flex>
          <ProfileTable />
        </Flex>
      )}
      <OnboardingLink onClick={onOpen} />

      {profile?.is_ceo && (
        <AddEditModal
          active={modalActive}
          onClose={() => setModalActive(false)}
          title="Добавить сотрудника"
        />
      )}

      <TutorialModal
        active={isOpen}
        onClose={onClose}
        screens={screens.map((screen) => screen.content)}
      />
      <ChangeModal
        active={changeNameModal}
        onClose={() => {
          setChangeNameModal(false);
        }}
        title="Сменить ФИО"
        children={<ChangeNameModal />}
      />
      <ChangeModal
        active={changeEmailModal}
        onClose={() => {
          setChangeEmailModal(false);
        }}
        title="Сменить E-mail"
        children={<ChangeEmailModal />}
      />
    </Flex>
  );
};
