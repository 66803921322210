import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import {
  IRemarksConstructionResponse,
  IRemarksTransmissionResponse,
  ISingleRemarkConstructionResponse,
  ISingleRemarkTransmissionResponse,
} from "./types";
import { RemarksEndpoints } from "../endpoints";

export const getRemarksConstructionList =
  (): AxiosPromise<IRemarksConstructionResponse> =>
    axiosInstance.get(RemarksEndpoints.REMARKS_CONSTRUCTION);

export const getSingleRemarkConstruction = (
  id: string
): AxiosPromise<ISingleRemarkConstructionResponse> =>
  axiosInstance.get(RemarksEndpoints.REMARK_CONSTRUCTION + `/${id}`);

export const applyRemarkConstruction = (id: string): AxiosPromise<undefined> =>
  axiosInstance.post(RemarksEndpoints.REMARK_CONSTRUCTION + `/${id}/done`);

export const getRemarksTransmissionList =
  (): AxiosPromise<IRemarksTransmissionResponse> =>
    axiosInstance.get(RemarksEndpoints.REMARKS_TRANSMISSION);

export const getSingleRemarkTransmission = (
  id: string
): AxiosPromise<ISingleRemarkTransmissionResponse> =>
  axiosInstance.get(RemarksEndpoints.REMARK_TRANSMISSION + `/${id}`);
