import {  useEffect, useMemo, useState } from "react";
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
} from "@tanstack/react-table";
import { Box, Center, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { FilterHeader } from "../Filter/FilterHeader";
import { Table } from "../lib/table/Table";
import { ISavingsCompensationAct } from "../../api/savingsCompensationActs/types";
import { useAppSelector } from "../../store";
import { fetchSavingsCompensationActs, resetCurentSavingsCompensationAct } from "../../store/slices/savingsCompensationActs";
import { formatAmount } from "../../helpers/formatAmount";
import { DownloadFilesModal } from "../Modal/ModalRight/DownloadFilesModal";
import {ContractCell} from "../ContractCell/ContractCell";
import { StatusCellTable } from "../StatusCellTable/StatusCellTable";
import { filterFunction } from "../../helpers/filterFunction";
import { convertDate } from "../../helpers/convertDate";
import { IFile } from "../../api/files/types";
import { OnboardingByPermission } from "../OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";

const columnHelper = createColumnHelper<ISavingsCompensationAct>();

const ProjectCell = (info: CellContext<ISavingsCompensationAct, string>) => (
  <Text noOfLines={2}>{info.renderValue()}</Text>
);

export function CompensationActTable({ filesModal, setFilesModal, dispatch }: any) {
  const { savingsCompensationActs, currentSavingsCompensationAct } = useAppSelector(
    (state) => state.savingsCompensationActsReducer
  );

  const [files, setFiles] = useState<IFile[] | undefined>()
  const FilesCell = (info: CellContext<ISavingsCompensationAct, string>) => (
    <Center>
      <ExternalLinkIcon onClick={() => {
        setFilesModal(true)
        setFiles(info.row.original.files)
        }} color="#2AB6A5" cursor="pointer" />
    </Center>
  );

  useEffect(() => {
    return () => {
      dispatch(resetCurentSavingsCompensationAct())
    }
  }, [])

  const columns = useMemo<Array<ColumnDef<ISavingsCompensationAct, string>>>(
    () => [
      columnHelper.accessor("status", {
        header: (info) => FilterHeader(info.column, info.table, "Статус"),
        cell: (info) => StatusCellTable(info.renderValue()),
        enableColumnFilter: true,
        meta: {
          filterSelect: true
        },
        filterFn: ({ original: { status } }, id, filterValues) =>
          filterFunction({ value: status, filterValues })
      }),
      columnHelper.accessor("number", {
        header: "Номер",
        enableColumnFilter: true,
      }),
      columnHelper.accessor("project_name", {
        header: "Проект",
        cell: ProjectCell,
      }),
      columnHelper.accessor("amount", {
        header: "Сумма компенсации",
        cell: (info) => formatAmount(info.getValue())
      }),
      columnHelper.accessor("contract_name", {
        header: "Договор",
        cell: (info) => ContractCell(info, String(info.cell.row.original.contract_id)),
        enableColumnFilter: true,
      }),
      columnHelper.accessor("start_date", {
        header: (info) => FilterHeader(info.column, info.table, "Дата начала"),
        cell: (info) => convertDate(info.renderValue()),
        enableColumnFilter: true,
        meta: {
          filterSelect: true
        },
        filterFn: ({ original: { start_date } }, id, filterValues) =>
          filterFunction({ value: start_date, filterValues })
      }),
      columnHelper.accessor("end_date", {
        header: (info) => FilterHeader(info.column, info.table, "Дата окончания"),
        cell: (info) => convertDate(info.renderValue()),
        enableColumnFilter: true,
        meta: {
          filterSelect: true
        },
        filterFn: ({ original: { end_date } }, id, filterValues) =>
          filterFunction({ value: end_date, filterValues })
      }),
      {
        id: "files",
        header: "Файлы",
        cell: FilesCell,
      },
    ],
    []
  );
  return (
    <>
      <Table data={savingsCompensationActs} columns={columns} fetchFunction={fetchSavingsCompensationActs} lastViewedDocument={currentSavingsCompensationAct?.id}/>
      <Box>
        <OnboardingByPermission permission={AccessRights.Purchase_RW} />
      </Box>
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        receivedFiles={files}
        onClose={() => setFilesModal(false)}
      />
    </>
  ) 
}
