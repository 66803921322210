import { RootState, useAppDispatch, useAppSelector } from "../../store";
import classNames from "classnames";
import { changeSidebarPosition } from "../../store/slices/profile";
import { SidebarItem } from "./SidebarItem";
import { ReactComponent as Arrow } from "../../images/svg/arrows/arrow.svg";
import { ReactComponent as MainLogo } from "../../images/svg/main-logo/logo.svg";
import { sidebarContent } from "../../shared/mock/sidebarContent";
import styles from "./sidebar.module.scss";
import {checkPermission} from "../../helpers/checkPermission";
import { useEffect, useState } from "react";

export function Sidebar() {
  const dispatch = useAppDispatch();
  const { isSidebarClosed } = useAppSelector(
    (state: RootState) => state.profile.profileData
  );
  const [updatedSidebarContent, setUpdatedSidebarContent] = useState(sidebarContent);
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );

  const { counters } = useAppSelector(
    (state: RootState) => state.mainInfo
  );

  const sidebarClass = classNames(styles.sidebar_wrapper, {
    [styles.closed]: isSidebarClosed,
  });

  const handleSidebarChange = () =>
    dispatch(changeSidebarPosition(!isSidebarClosed));

  useEffect(() => {
    const updatedContent = updatedSidebarContent.map((item) => {
      if (item.href === "/add-request") {
        return ({
          ...item,
          notice: counters?.counter?.additional_requests.count ?? 0,
          overdueNotice: counters?.counter?.additional_requests.expired
        })
      } else {
        return item
      }
    });
    setUpdatedSidebarContent(updatedContent);
  }, [counters])

  return (
    <aside className={sidebarClass}>
      <div className={styles.logo_details}>
        <MainLogo width={40} height={40} />
        <span className={styles.logo_name}>Личный кабинет</span>
      </div>
      <div className={styles.sidebar}>
        <ul className={styles.nav_links}>
          {updatedSidebarContent.map((item) => (
            ((item.permissions && checkPermission(item.permissions, permissions)) || !("permissions" in item)) && (
              <SidebarItem key={item.id} item={item}/>
            )
          ))}
        </ul>
      </div>
      <div className={styles.close} onClick={handleSidebarChange}>
        <div className={styles.close_logo_container}>
          <Arrow />
        </div>
        <span className={styles.logo_name}>Свернуть</span>
      </div>
    </aside>
  );
}
