import { useMemo } from "react";
import {useAppDispatch, useAppSelector} from "../../store";
import {
  createColumnHelper,
  CellContext,
  ColumnDef, Row,
} from "@tanstack/react-table";
import { Box, Center, Flex, Icon, Link, Text } from "@chakra-ui/react";
import { AddIcon, AttachmentIcon, MinusIcon } from "@chakra-ui/icons";
import { Table } from "../lib/table/Table";
import { ReactComponent as EditPen } from "../../images/svg/edit/edit-pen.svg";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { ISingleContractWorkCompletedAct } from "../../api/contracts/types";
import { formatAmount } from "../../helpers/formatAmount";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { fetchSingleWorkCompletedActs, resetCurrenWorkCompletedAct } from "../../store/slices/workCompletedActs";
import { fetchSingleEstimate, resetCurrentEstimate } from "../../store/slices/estimates";
import { ISingleWorkCompletedActsResponse } from "../../api/workCompletedActs/types";

const columnHelper = createColumnHelper<ISingleContractWorkCompletedAct>();

const SubRowTitleCell = (props: { value: string }) => (
  <Flex pl={5} gap={2}>
    <Center>
      <Document fill="#2AB6A5" />
    </Center>
    <Link color="#2AB6A5" href="#">
      {props.value}
    </Link>
  </Flex>
);

const TitleCell = (
  info: CellContext<ISingleContractWorkCompletedAct, string>
) => {
  const { row } = info;

  return row.depth === 0 ? (
    <Flex gap={3} justify="space-between" userSelect="none">
      <Text flex={1} noOfLines={2}>
        {row.getCanExpand() && (
          <Icon
            cursor="pointer"
            mr={2}
            fontSize="12px"
            as={info.row.getIsExpanded() ? MinusIcon : AddIcon}
            onClick={info.row.getToggleExpandedHandler()}
          />
        )}
        {info.getValue()}
      </Text>
      <Center gap={2} cursor="pointer" color="#2AB6A5">
        {row.original.has_files && <Icon as={AttachmentIcon} fill="#2AB6A5" />}
        {row.original.edo && <Icon as={EditPen} fill="#2AB6A5" />}
      </Center>
    </Flex>
  ) : (
    <SubRowTitleCell value={info.getValue()} />
  );
};

const StatusCell = (
  info: CellContext<ISingleContractWorkCompletedAct, string>
) => (
  <Text
    fontSize={14}
    py={2}
    px={3}
    bg="#469DDD40"
    borderRadius={6}
    display="inline-block"
    lineHeight={4}
  >
    {info.renderValue()}
  </Text>
);

const SumSell = (
  info: CellContext<ISingleContractWorkCompletedAct, string>
) => <Box whiteSpace="nowrap">{ formatAmount(info.getValue())}</Box>;

export function ActsTable() {
  const { currentContractWorkCompletedActs } = useAppSelector(
    (state) => state.contracts
  );
  const navigate = useNavigate();
  const status = ["Черновик", "Рабочая", "Выполнена", "Утверждена", "Отклонена"];

  const dispatch = useAppDispatch();

  const handleActRowClick = (row: Row<ISingleContractWorkCompletedAct>) => {
    row.toggleSelected();

    if (row.getIsSelected()) {
      dispatch(resetCurrenWorkCompletedAct());
      dispatch(resetCurrentEstimate())
    } else {
      dispatch(fetchSingleWorkCompletedActs(row.original.id))
    }
  };

  const handleDblClick = (id:number) => {
    navigate(`/my-documents/contracts/work-completed-act/${id}`)
  }

  const sortedActs = [...currentContractWorkCompletedActs]
  .filter(item => status.includes(item.status))
  .sort((a, b) => {
    const getDate = (name: string) => {
      const matchDate = name.match(/(\d{2}\.\d{2}\.\d{4} \d{1,2}:\d{2}:\d{2})/);
      if (matchDate) {
        const reversedDate = matchDate[0].replace(/\b\d\b/g, '0$&').replace(/^(\d{2})\.(\d{2})\.(\d{4}) (\d{2}:\d{2}:\d{2})$/, '$3-$2-$1 $4');
        return dayjs(reversedDate, "YYYY-MM-DD HH:mm:ss", true);
      }
      return null;
    };

    const dateA = getDate(a.name);
    const dateB = getDate(b.name);

    return dateB && dateA ? dateB.diff(dateA) : 0;
  });


  const columns = useMemo<
    Array<ColumnDef<ISingleContractWorkCompletedAct, string>>
  >(
    () => [
      columnHelper.accessor("name", {
        header: "Название",
        id: "title",
        cell: TitleCell,
      }),
      columnHelper.accessor((row) => row.status, {
        id: "status",
        header: "Статус",
        cell: StatusCell,
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: SumSell,
      }),
    ],
    []
  );

  return <Table data={sortedActs} columns={columns} rowClick={handleActRowClick} dblClickHandler={handleDblClick} maxHeight="360px" />;
}
