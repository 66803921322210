import { AsyncThunk, Dispatch } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { useAppDispatch } from "../store";
import { downloadRequestedFile } from "./downloadRequestedFile";

export const useUniversalDownloadFile = () => {
  const dispatch = useAppDispatch();

  const downloadFile = async (
    id: string | number | undefined,
    setIsSpinnerRunning: (arg: boolean) => void,
    fetchFunction: AsyncThunk<
      any,
      any,
      {
        rejectValue: AxiosError;
        state?: unknown;
        dispatch?: Dispatch | undefined;
        extra?: unknown;
        serializedErrorType?: unknown;
        pendingMeta?: unknown;
        fulfilledMeta?: unknown;
        rejectedMeta?: unknown;
      }
    >,
    params?: any,
  ) => {
    setIsSpinnerRunning(true);
    const actionPayload = params ? { id, params } : id;
    dispatch(fetchFunction(actionPayload))
    .then((res: any) => {
        const fileGuid = res.payload.data.guid
        downloadRequestedFile(fileGuid, setIsSpinnerRunning);
    })
    .catch(() => setIsSpinnerRunning(false));
  };

  return {
    downloadFile,
  };
};
