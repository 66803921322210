import { useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  CellContext,
  ColumnDef,
} from "@tanstack/react-table";
import { Box, Center, Flex, Link, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Table } from "../../lib/table/Table";
import { ReactComponent as EditPen } from "../../../images/svg/edit/edit-pen.svg";
import { DownloadFilesModal } from "../../Modal/ModalRight/DownloadFilesModal";
import { useAppSelector } from "../../../store";
import {
  fetchPayments,
  fetchSinglePayment,
  resetCurrentPayment,
} from "../../../store/slices/payments";
import { IPayment } from "../../../api/payments/types";
import { StatusCellTable } from "../../StatusCellTable/StatusCellTable";
import { formatAmount } from "../../../helpers/formatAmount";
import { useNavigate } from "react-router-dom";
import { changeCounters } from "../../../store/slices/mainInfo";
import { OnboardingByPermission } from "../../OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../../shared/mock/sidebarContent";

const columnHelper = createColumnHelper<IPayment>();

const TitleCell = (info: CellContext<IPayment, string>) => {
  return (
    <Flex gap={4} justify="space-between">
      <Text noOfLines={2}>{info.getValue()}</Text>
      <Center>
        <EditPen fill="#2AB6A5" cursor="pointer" />
      </Center>
    </Flex>
  );
};

const ContractCell = (info: CellContext<IPayment, string>) => {
  const navigate = useNavigate()
  return (
    <Link color="#2AB6A5" noOfLines={2} onClick={() => {
      navigate(
        `/my-documents/contracts/${info.row.original.contract_id}`
      );
    }}>
      {info.renderValue()}
    </Link>
  )
};

const ManagerCell = (info: CellContext<IPayment, string>) => (
  <Text noOfLines={2}>{info.renderValue()}</Text>
);

export function PaymentTable({ dispatch, filesModal, setFilesModal }: any) {
  const { payments } = useAppSelector((state) => state.payments);
  const { currentPayment } = useAppSelector((state) => state.payments);
  const { counters } = useAppSelector((state) => state.mainInfo);
  const [isDisable, setIsDisable] = useState<boolean>(false);

  const files = currentPayment ? currentPayment : [];

  const FilesCell = (info: CellContext<IPayment, string>) => (
    <Center>
      <ExternalLinkIcon
        onClick={() => {
          setFilesModal(true);
          dispatch(fetchSinglePayment(info.row.original.guid));
          setIsDisable(info.row.original.status != "Рабочая")
        }}
        color={"rgb(42 182 165)"}
        cursor="pointer"
      />
    </Center>
  );

  useEffect(() => {
    const updatedCounters = {...counters, payments: 0}
    dispatch(changeCounters(updatedCounters))
    return () => {
      dispatch(resetCurrentPayment())
    }
  }, [])

  const columns = useMemo<Array<ColumnDef<IPayment, string>>>(
    () => [
      columnHelper.accessor("a101_org_name", {
        header: "Организация",
      }),
      columnHelper.accessor("status", {
        header: "Статус",
        cell: (info) => StatusCellTable(info.renderValue())
      }),
      columnHelper.accessor("name", {
        header: "Название",
        cell: TitleCell,
      }),
      columnHelper.accessor("contract_name", {
        header: "Договор",
        cell: ContractCell,
        enableColumnFilter: true,
      }),
      columnHelper.accessor("amount", {
        header: "Сумма",
        cell: (info) => formatAmount(info.renderValue())
      }),
      columnHelper.accessor("project_name", {
        header: "Проект",
      }),
      columnHelper.accessor("executor", {
        header: "Исполнитель",
        cell: ManagerCell,
      }),
      {
        id: "files",
        header: "Файлы",
        cell: FilesCell,
      },
    ],
    []
  );

  return (
    <>
      <Table dblClickDisabled={true} data={payments} columns={columns} fetchFunction={fetchPayments} />
      <Box>
          <OnboardingByPermission permission={AccessRights.AccessAct_RW} />
      </Box>
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        isDisabled={isDisable}
        receivedFiles={files}
      />
    </>
  );
}
