import {Flex, Radio, RadioGroup, Stack, Text} from "@chakra-ui/react";
import {BlockTitle} from "../lib/title/Title";
import {DocumentsFields} from "../CreateActBasedOnEstimateComponents/DocumentsFields";
import {useState} from "react";
import { checkPermission } from "../../helpers/checkPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";
import { RootState, useAppSelector } from "../../store";

export function SingleEstimateActPrintData() {
  const { permissions } = useAppSelector(
    (state: RootState) => state.auth.authData
  );
  const [radioValue, setRadioValue] = useState<string>("2");
  const isDisabledByPermission = !checkPermission(AccessRights.WorkCompletedAct_RW, permissions)
  return(
    <Flex direction="column" gap={2}>
      <BlockTitle>Аванасовые документы</BlockTitle>
      <RadioGroup size="lg" colorScheme="teal" defaultValue="2" onChange={setRadioValue}>
        <Stack h="40px" spacing={5} direction="row" align="center">
          <Radio isDisabled={isDisabledByPermission} value="1" borderColor="#AAB0C1">
            <Text fontSize="16px">Это первый платеж</Text>
          </Radio>
          <Radio
            isDisabled={isDisabledByPermission}
            alignItems="center"
            alignSelf="center"
            value="2"
            borderColor="#AAB0C1"
          >
            <Text fontSize="16px">Это не первый платеж</Text>
          </Radio>
        </Stack>
      </RadioGroup>
      {radioValue === "2" && <DocumentsFields />}
    </Flex>
  )
}
