import { AxiosPromise } from "axios";
import { axiosInstance } from "../instance";
import { Files, WorkCompletedActsEndpoints } from "../endpoints";
import {
  ICreateWorkCompletedActRequest,
  ICreateWorkCompletedActResponse,
  IPrintFormWorkCompletedActRequest,
  ISingleWorkCompletedActsResponse,
  IWorkCompletedActsResponse,
} from "./types";
import { IPrintFormResponse } from "../directionLetters/types";

export const getWorkCompletedActs =
  (): AxiosPromise<IWorkCompletedActsResponse> =>
    axiosInstance.get(WorkCompletedActsEndpoints.WORK_COMPLETED_ACTS);

export const createWorkCompletedAct = (
  params: ICreateWorkCompletedActRequest
): AxiosPromise<ICreateWorkCompletedActResponse> => {
  params.status = 'Рабочая';
  return axiosInstance.post(
      WorkCompletedActsEndpoints.CREATE_WORK_COMPLETED_ACT,
      params
  );

}

export const getSingleWorkCompletedActs = (
  id: string
): AxiosPromise<ISingleWorkCompletedActsResponse> =>
  axiosInstance.get(
    WorkCompletedActsEndpoints.SINGLE_WORK_COMPLETED_ACT +
    `/${id}`
  );

  export const updateWorkCompletedAct = (
    id: string,
    params: ICreateWorkCompletedActRequest
  ): AxiosPromise<ICreateWorkCompletedActResponse> => {
    params.status = "Черновик";
    return axiosInstance.post(
      WorkCompletedActsEndpoints.SINGLE_WORK_COMPLETED_ACT + `/${id}/update`,
      params
    );
  };

  export const getPrintForm = (
    id: string,
    params: IPrintFormWorkCompletedActRequest
  ): AxiosPromise<IPrintFormResponse> =>
    axiosInstance.post(
      WorkCompletedActsEndpoints.SINGLE_WORK_COMPLETED_ACT +
        `/${id}/request-print-form`,
      params
    );