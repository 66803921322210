import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Icon,
} from "@chakra-ui/react";
import { MainLayout } from "../../components/MainLayout/MainLayout";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { BlockTitle } from "../../components/lib/title/Title";
import {
  OutlineButton,
  PrimaryButton,
} from "../../components/lib/button/Button";
import { ReactComponent as Document } from "../../images/svg/sidebar/document.svg";
import { ReactComponent as Reject } from "../../images/svg/remarks/reject.svg";
import { ReactComponent as DblAccept } from "../../images/svg/remarks/dblaccept.svg";
import { ReactComponent as Accept } from "../../images/svg/remarks/accept.svg";
import { SingleRemarkTransmissionInformationBlock } from "../../components/SingleRemarkTransmissionBlock/SingleRemarkTransmissionInformationBlock";
import { Comment } from "../../components/Comment/Comment";
import { FilesTable } from "../../components/SingleRemarkTransmissionBlock/FilesTable";
import { DownloadFilesModal } from "../../components/Modal/ModalRight/DownloadFilesModal";
import { useEffect, useState } from "react";
import { RootState, useAppSelector } from "../../store";
import { fetchSingleRemarkTransmission } from "../../store/slices/remarks";
import { OnboardingByPermission } from "../../components/OnboardingByPermission/OnboardingByPermission";
import { AccessRights } from "../../shared/mock/sidebarContent";

export function SingleRemarkTransmissionPage({ goBack, id, dispatch, filesModal, setFilesModal }: any) {

  useEffect(() => {
    id && dispatch(fetchSingleRemarkTransmission(id));
  }, [dispatch, id]);

  const { currentRemarkTransmission } = useAppSelector(
    (state: RootState) => state.remarks
  );

  const [files, setFiles] = useState(currentRemarkTransmission?.files);
  
  useEffect(() => {
    setFiles(currentRemarkTransmission?.files)
  }, [currentRemarkTransmission?.files])

  return (
    <MainLayout>
      <Flex direction="column" gap={10} p={6}>
        <Flex direction="column" gap={4}>
          <Breadcrumb
            fontSize={14}
            separator={<Box w={1} h={1} bg="#8089A2" borderRadius="50%" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/main">Главная</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/remarks">Замечания</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="/remarks/transmission">Замечания при передаче ОН</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink color="#8089A2">
                {`Предприёмка №${currentRemarkTransmission?.number}`}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Flex justify="space-between" align="center">
            <Flex align="center" gap={4}>
              <ArrowBackIcon boxSize={6} cursor="pointer" onClick={() => goBack("/remarks/transmission")} />
              <BlockTitle>{`Предприёмка №${currentRemarkTransmission?.number}`}</BlockTitle>
            </Flex>

            <ButtonGroup spacing={3}>
              <OutlineButton
                leftIcon={<Icon as={Document} fill={"#E8363D"} />}
                gap={2}
                onClick={() => setFilesModal(true)}
              >
                Файлы
              </OutlineButton>
              <PrimaryButton leftIcon={<Reject />}>Отклонить</PrimaryButton>
              <PrimaryButton leftIcon={<DblAccept />}>Устранено</PrimaryButton>
              <PrimaryButton leftIcon={<Accept />}>Принять</PrimaryButton>
            </ButtonGroup>
          </Flex>
          <Grid templateColumns="1fr 504px" gap={4}>
            <GridItem>
              <SingleRemarkTransmissionInformationBlock />
            </GridItem>
            <Flex gap="16px" flexDirection="column">
              <Comment
                title="Текст замечания"
                currentComment={currentRemarkTransmission?.text_of_the_remark}
              />
              <Comment title="Комментарий подрядчику" currentComment={currentRemarkTransmission?.comment_to_the_contractor} />
              <Comment
                title="Комментарий контрагента"
                isEdited={true}
                currentComment={currentRemarkTransmission?.counterparty_comment}
              />
              <FilesTable />
            </Flex>
          </Grid>
        </Flex>

        <Box>
          <OnboardingByPermission permission={AccessRights.TransferNote_RW} />
        </Box>

      </Flex>
      <DownloadFilesModal
        title="Файлы"
        active={filesModal}
        onClose={() => setFilesModal(false)}
        receivedFiles={files}
        fileAttach={() => {}}
      />
    </MainLayout>
  );
}
